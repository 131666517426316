import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../../actions';
import {
  Avatar,
  Box,
  Button,
  Container,
  Grid,
  Snackbar,
  TextField,
  Slide,
  Typography,
  Alert,
  AlertTitle,
} from '@mui/material';
import BaseComponent from '../containers/BaseComponent';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Link from 'gatsby-link';
import { styled } from '@mui/material/styles';

function mapStateToProps(state) {
  return {
    passEmail: state.sendPassResetEmail,
  };
}

const PREFIX = 'ResetPassword';
const classes = {
  paper: `${PREFIX}-paper`,
  avatar: `${PREFIX}-avatar`,
  form: `${PREFIX}-form`,
  submit: `${PREFIX}-submit`,
};
const Root = styled('div')(({ theme }) => ({
  [`& .${classes.paper}`]: {
    marginTop: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  [`& .${classes.avatar}`]: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  [`& .${classes.submit}`]: {
    margin: theme.spacing(3, 0, 2),
  },
}));

class ResetPassword extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      emailError: false,
      emailErrorMessage: '',
      isEmailValid: null,
      btnDisabled: true,
      error: false,
      snackbarOpened: false,
      snackbarMessage: '',
      snackbarSeverity: '',
      emailWasSent: false,
    };
  }

  setSnackbarMessage(message, severity) {
    this.setState({
      snackbarMessage: message,
      snackbarSeverity: severity,
      snackbarOpened: true,
    });
  }

  resetPassword() {
    let data = {
      email: this.state.email,
    };
    this.props.actions.sendPassResetEmail(data);
    this.setState({ btnDisabled: true });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.email !== this.state.email) {
      const regExp = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!this.state.email) {
        this.setState({ emailErrorMessage: 'Podaj email' });
      } else if (!regExp.test(this.state.email)) {
        this.setState({
          emailError: true,
          emailErrorMessage: 'Nieprawidłowy format email',
          btnDisabled: true,
        });
      } else {
        this.setState({
          emailError: false,
          emailErrorMessage: '',
          btnDisabled: false,
        });
      }
    }
    if (prevProps.passEmail !== this.props.passEmail) {
      let passEmail = this.props.passEmail;
      if (passEmail.data) {
        this.setSnackbarMessage(passEmail.data.message, 'success');
        this.setState({ btnDisabled: false, emailWasSent: true });
      } else {
        this.setSnackbarMessage(passEmail.reason, 'error');
        this.setState({ btnDisabled: false });
      }
    }
  }

  render() {
    return (
      <Root>
        <Snackbar
          open={this.state.snackbarOpened}
          TransitionComponent={Slide}
          autoHideDuration={6000}
          onClose={() => this.setState({ snackbarOpened: false })}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert
            elevation={6}
            variant='filled'
            severity={this.state.snackbarSeverity}
            onClose={() => this.setState({ snackbarOpened: false })}
          >
            {this.state.snackbarMessage}
          </Alert>
        </Snackbar>

        {!this.state.emailWasSent ? (
          <>
            <Container component='main' maxWidth='xs'>
              <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                  <LockOutlinedIcon />
                </Avatar>
                <Typography component='h1' variant='h5' paragraph>
                  Resetowanie hasła
                </Typography>

                <TextField
                  value={this.state.email}
                  name='email'
                  autoComplete='username'
                  id='outlined-basic'
                  label='Podaj e-mail'
                  variant='outlined'
                  onChange={this.handleValueChange.bind(this)}
                  error={this.state.emailError}
                  helperText={this.state.emailErrorMessage}
                  fullWidth
                />

                <Button
                  variant='contained'
                  color='primary'
                  className={classes.submit}
                  onClick={this.resetPassword.bind(this)}
                  disabled={this.state.btnDisabled}
                  fullWidth
                >
                  wyślij
                </Button>
                <Grid container alignItems={'flex-start'}>
                  <Grid item xs>
                    <Link
                      className='link-inline'
                      to='/user/sign-in'
                      variant='body2'
                    >
                      wróć
                    </Link>
                  </Grid>
                </Grid>
              </div>
            </Container>
          </>
        ) : (
          <>
            <Container>
              <Box p={3}>
                <Typography variant={'h4'} align='center'>
                  Potwierdzenie wysłania linka
                </Typography>
              </Box>
              <Alert severity={'success'}>
                <AlertTitle>
                  {'Na podany email wysłano link do resetowania hasła'}
                </AlertTitle>
                Możesz zamknąć tę kartę.
              </Alert>
            </Container>
          </>
        )}
      </Root>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
