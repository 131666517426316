import React from 'react';
import Layout from '../../templates/layout';
import ResetPassword from '../../components/password/resetpassword';
import Seo from '../../components/utils/Seo';

const ResetPasswordPage = () => (
  <Layout>
    <ResetPassword />
  </Layout>
);

export const Head = ({ location }) => (
  <Seo location={location} title='Resetowanie hasła' description='Zaloguj się do Zapłatomatu aby zadządzać płatnościami i operacjami na swoim koncie'/>
);


export default ResetPasswordPage;
